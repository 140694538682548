export default {
  namespaced: true,
  state: {
    listArrive_Teacher: null,
    listArrive_Type: null,
    listArrive_TeacherAll: null,
    reportArriveTeacherOnce: null,
  },
  getters: {
    _listArrive_Teacher: (state) => state.listArrive_Teacher,
    _listArrive_Type: (state) => state.listArrive_Type,
    _listArrive_TeacherAll: (state) => state.listArrive_TeacherAll,
    _reportArriveTeacherOnce: (state) => state.reportArriveTeacherOnce,
  },
  mutations: {},
  actions: {
    _listArrive_Type({ state, rootGetters }) {
      return window.axios
        .post("arrive_teacher/_listArrive_Type")
        .then((res) => (state.listArrive_Type = res.data));
    },
     _listArrive_Teacher({ state }, params) {
      // let dataTea = await rootGetters._setDefTeacher;
      // if (dataTea)
        return window.axios
          .post("arrive_teacher/_listArrive_Teacher", params)
          .then((res) => (state.listArrive_Teacher = res.data));
    },
    _listArrive_TeacherAll({ state }, params) {
      return window.axios
        .post("arrive_teacher/_listArrive_TeacherAll", params)
        .then((res) => {
          state.listArrive_TeacherAll = res.data
          return res.data;
        });
    },
    _reportArriveTeacherOnce({ state }, params) {
      return window.axios
        .post("arrive_teacher/_reportArriveTeacherOnce", params)
        .then((res) => (state.reportArriveTeacherOnce = res.data));
    },

    _insert_arrive_leave({ rootGetters }, params) {
      return window.axios
        .post("arrive_teacher/_insert_arrive_leave", params, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => res.data);
    },
    _update_arrive_leave({ rootGetters }, params) {
      return window.axios
        .post("arrive_teacher/_update_arrive_leave", params, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => res.data);
    },
    _delete_arrive_leave({ rootGetters }, params) {
      return window.axios
        .post("arrive_teacher/_delete_arrive_leave", params)
        .then((res) => res.data);
    },
  },
};
