export default {
  namespaced: true,
  state: {
    list_Cooperation_Product_Type: null,
    list_Cooperation_Unit: null,
    list_Cooperation_Account: null,
    list_Cooperation_Order_Account: null,
    list_Cooperation_Relation_Order_Account_Once: null,
    list_Cooperation_Relation_Order_Account: null,
    list_Cooperation_Product: null,
    list_Cooperation_Product_Not_In_Stock: null,
    list_Cooperation_Stock: null,
    list_Cooperation_Payment: null,
    list_Cooperation_Accounting_List: null,
    list_Cooperation_Payment_Report: null,
    export_order_payment_per_day: null,
  },
  getters: {
    _list_Cooperation_Product_Type: (state) => state.list_Cooperation_Product_Type,
    _list_Cooperation_Unit: (state) => state.list_Cooperation_Unit,
    _list_Cooperation_Account: (state) => state.list_Cooperation_Account,
    _list_Cooperation_Order_Account: (state) => state.list_Cooperation_Order_Account,
    _list_Cooperation_Relation_Order_Account_Once: (state) => state.list_Cooperation_Relation_Order_Account_Once,
    _list_Cooperation_Relation_Order_Account: (state) => state.list_Cooperation_Relation_Order_Account,
    _list_Cooperation_Product: (state) => state.list_Cooperation_Product,
    _list_Cooperation_Product_Not_In_Stock: (state) => state.list_Cooperation_Product_Not_In_Stock,
    _list_Cooperation_Stock: (state) => state.list_Cooperation_Stock,
    _list_Cooperation_Payment: (state) => state.list_Cooperation_Payment,
    _list_Cooperation_Accounting_List: (state) => state.list_Cooperation_Accounting_List,
    _list_Cooperation_Payment_Report: (state) => state.list_Cooperation_Payment_Report,
    _export_order_payment_per_day: (state) => state.export_order_payment_per_day,
  },
  mutations: {},
  actions: {
    _export_order_payment_per_day({ state }, params) {
      return axios
        .post("cooperation/_export_order_payment_per_day", params)
        .then((res) => (state.export_order_payment_per_day = res.data));
    },
    _list_Cooperation_Product_Type({ state }) {
      return window.axios
        .post("cooperation/_list_Cooperation_Product_Type")
        .then((res) => (state.list_Cooperation_Product_Type = res.data));
    },
    _list_Cooperation_Unit({ state }) {
      return window.axios
        .post("cooperation/_list_Cooperation_Unit")
        .then((res) => (state.list_Cooperation_Unit = res.data));
    },
    _list_Cooperation_Payment({ state }, params) {
      return window.axios
        .post("cooperation/_list_Cooperation_Payment", params)
        .then((res) => (state.list_Cooperation_Payment = res.data));
    },
    _list_Cooperation_Account({ state }) {
      return window.axios
        .post("cooperation/_list_Cooperation_Account")
        .then((res) => (state.list_Cooperation_Account = res.data));
    },
    _list_Cooperation_Order_Account({ state }) {
      return window.axios
        .post("cooperation/_list_Cooperation_Order_Account")
        .then((res) => (state.list_Cooperation_Order_Account = res.data));
    },
    _list_Cooperation_Relation_Order_Account_Once({ state }, params) {
      return window.axios
        .post("cooperation/_list_Cooperation_Relation_Order_Account_Once", params)
        .then((res) => (state.list_Cooperation_Relation_Order_Account_Once = res.data));
    },
    _list_Cooperation_Relation_Order_Account({ state }) {
      return window.axios
        .post("cooperation/_list_Cooperation_Relation_Order_Account")
        .then((res) => (state.list_Cooperation_Relation_Order_Account = res.data));
    },
    //--------------- Unit ----------------//
    _insert_Cooperation_Unit({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_insert_Cooperation_Unit", params, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => res.data);
    },
    _update_Cooperation_Unit({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_update_Cooperation_Unit", params)
        .then((res) => res.data);
    },
    _delete_Cooperation_Unit({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_delete_Cooperation_Unit", params)
        .then((res) => res.data);
    },
    //--------------- Unit ----------------//
    _insert_Cooperation_Account({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_insert_Cooperation_Account", params, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => res.data);
    },
    _insert_Cooperation_Order_Account({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_insert_Cooperation_Order_Account", params, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => res.data);
    },
    _insert_Cooperation_Relation_Order_Account_Once({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_insert_Cooperation_Relation_Order_Account_Once", params)
        .then((res) => res.data);
    },
    _update_Cooperation_Account({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_update_Cooperation_Account", params)
        .then((res) => res.data);
    },
    _update_Cooperation_Order_Account({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_update_Cooperation_Order_Account", params)
        .then((res) => res.data);
    },
    _delete_Cooperation_Account({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_delete_Cooperation_Account", params)
        .then((res) => res.data);
    },
    _delete_Cooperation_Order_Account({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_delete_Cooperation_Order_Account", params)
        .then((res) => res.data);
    },
    _delete_Cooperation_Relation_Order_Account_Once({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_delete_Cooperation_Relation_Order_Account_Once", params)
        .then((res) => res.data);
    },

    // ------------------ Product -------------------

    // ------------------ Product -------------------
    _list_Cooperation_Product({ state }) {
      return window.axios
        .post("cooperation/_list_Cooperation_Product")
        .then((res) => (state.list_Cooperation_Product = res.data));
    },
    _list_Cooperation_Product_Not_In_Stock({ state },params) {
      return window.axios
        .post("cooperation/_list_Cooperation_Product_Not_In_Stock",params)
        .then((res) => (state.list_Cooperation_Product_Not_In_Stock = res.data));
    },
    _insert_Cooperation_Product({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_insert_Cooperation_Product", params, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => res.data);
    },
    _update_Cooperation_Product({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_update_Cooperation_Product", params, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => res.data);
    },
    _delete_Cooperation_Product({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_delete_Cooperation_Product", params)
        .then((res) => res.data);
    },
    // ------------------ Product -------------------
    // ------------------ Stock -------------------
    _list_Cooperation_Stock({ state }, params) {
      return window.axios
        .post("cooperation/_list_Cooperation_Stock", params)
        .then((res) => (state.list_Cooperation_Stock = res.data));
    },
    _insert_Cooperation_Stock({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_insert_Cooperation_Stock", params, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => res.data);
    },
    _update_Cooperation_Stock({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_update_Cooperation_Stock", params, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => res.data);
    },
    _delete_Cooperation_Stock({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_delete_Cooperation_Stock", params)
        .then((res) => res.data);
    },
    // ------------------ Stock -------------------
    // ------------------ Payment -------------------
    _list_Cooperation_Payment_Report({ state }, params) {
      return window.axios
        .post("cooperation/_list_Cooperation_Payment_Report", params)
        .then((res) => (state.list_Cooperation_Payment_Report = res.data));
    },
    _list_Cooperation_Payment({ state }, params) {
      return window.axios
        .post("cooperation/_list_Cooperation_Payment", params)
        .then((res) => (state.list_Cooperation_Payment = res.data));
    },
    _transferInshelf_Cooperation_Payment({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_transferInshelf_Cooperation_Payment", params, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => res.data);
    },
    _insert_Cooperation_Payment({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_insert_Cooperation_Payment", params, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => res.data);
    },
    _update_Cooperation_Payment({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_update_Cooperation_Payment", params, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => res.data);
    },
    _update_Cooperation_Payment_Once({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_update_Cooperation_Payment_Once", params, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => res.data);
    },
    _delete_Cooperation_Payment({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_delete_Cooperation_Payment", params)
        .then((res) => res.data);
    },
    // ------------------ Payment -------------------
    // ------------------ Accounting_List -------------------
    _list_Cooperation_Accounting_List({ state }, params) {
      return window.axios
        .post("cooperation/_list_Cooperation_Accounting_List", params)
        .then((res) => (state.list_Cooperation_Accounting_List = res.data));
    },
    _insert_Cooperation_Accounting_List({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_insert_Cooperation_Accounting_List", params, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => res.data);
    },
    _update_Cooperation_Accounting_List({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_update_Cooperation_Accounting_List", params, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => res.data);
    },
    _delete_Cooperation_Accounting_List({ rootGetters }, params) {
      return window.axios
        .post("cooperation/_delete_Cooperation_Accounting_List", params)
        .then((res) => res.data);
    },
    // ------------------ Accounting_List -------------------
  },
};
