export default {
  namespaced: true,
  state: {
    listMenu_Main_Group: null,
    listMenu_Main_Group_Tea: null
  },
  getters: {
    _listMenu_Main_Group: (state) => state.listMenu_Main_Group,
    _listMenu_Main_Group_Tea: (state) => state.listMenu_Main_Group_Tea,
  },
  mutations: {},
  actions: {
    _listMenu_Main_Group({ state }, params) {
      return window.axios
        .post("menu_main_group/_listMenu_Main_Group", params)
        .then((res) => (state.listMenu_Main_Group = res.data));
    },
    _listMenu_Main_Group_Tea({ state }, params) {
      return window.axios
        .post("menu_main_group/_listMenu_Main_Group_Tea", params)
        .then((res) => (state.listMenu_Main_Group_Tea = res.data));
    },
  },
};
