import Vue from 'vue'
import Vuex from 'vuex'
import school_user from './school_user/index'
import teacher from "./teacher/index";
import arrive_teacher from "./arrive_teacher/index";
import menu_main_group from "./menu_main_group/index";
import authentication from "./authentication/index";
import vote from "./vote/index";
import cooperation from "./cooperation/index";
import finance from "./finance/index";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    defTeacher: null,
    defSchoolUser: null,
  },
  getters: {
    _setDefTeacher: (state) => state.defTeacher,
    _setDefSchoolUser: (state) => state.defSchoolUser,
  },
  mutations: {},
  actions: {
    _setDefTeacher(context, params) {
      context.state.defTeacher = params;
    },
    _setDefSchoolUser(context, params) {
      context.state.defSchoolUser = params;
    },
  },
  modules: {
    school_user,
    teacher,
    arrive_teacher,
    menu_main_group,
    authentication,
    vote,
    cooperation,
    finance,
  },
});
