export default {
  namespaced: true,
  state: {
    listSchool_User: null,
    listSchool_UserAll: null
  },
  getters: {
    _listSchool_User: (state) => state.listSchool_User,
    _listSchool_UserAll: (state) => state.listSchool_UserAll,
  },
  mutations: {},
  actions: {
    _listSchool_User({state}, params) {
      return window.axios
        .post("school_user/_listSchool_User", params)
        .then((res) => (state.listSchool_User = res.data));
    },
    _listSchool_UserAll({state}, params) {
      return window.axios
        .post("school_user/_listSchool_UserAll", params)
        .then((res) => (state.listSchool_UserAll = res.data));
    },
  },
};
