<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img v-if="setDefSchoolUser" alt="School Logo" class="shrink mr-2 rounded-circle ml-1" contain :src="`https://jm2.big24u.com/images/logos/${setDefSchoolUser?.sch_user_token || ''
          }.jpg`" transition="scale-transition" width="40" />
        <!-- :src="`https://jm2.big24u.com/images/logos/${listSchool_User1?.sch_user_token}.jpg`" -->
        <a href="https://jm2.big24u.com?ref=1.3" style="text-decoration: none" class="white--text">{{ setDefSchoolUser?.sch_user_name || "" }} 
          {{ versionApp }}
        </a>
      </div>

      <v-spacer></v-spacer>

      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main>
      <!-- profile={{ profile }}
      profile1={{ profile1 }} -->
      <v-dialog v-model="dialogLoading" persistent transition="dialog-transition">
        <v-card color="primary" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
        <!-- Please wait... -->
      </v-dialog>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import liff from "@line/liff";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  name: "App",
  data: () => ({
    versionApp: process.env.VUE_APP_VERSION,
    listSchool_User1: {
      sch_user_token: null,
      sch_user_name: null,
    },
    profile: null,
    profile1: null,
    dialogLoading: false,
    parameterFromURL:null
  }),
  computed: {
    ...mapGetters({
      setDefSchoolUser: "_setDefSchoolUser",
      setDefTeacher: "_setDefTeacher",
    }),
  },
  watch: {
    listSchool_User() {
      if (this.listSchool_User.length > 0) {
        this.listSchool_User1 = this.listSchool_User[0];
      }
    },
  },
  methods: {
    getVariable() {
      var queryString = "";

      if (process.env.NODE_ENV == "development") {
        queryString = "?home.6a3a257c80d9f7a0d77e72d7bdcb21f9";
      } else {
        queryString = window.location.search;
        // this.profile = window.location.search;
      }
      // queryString = window.location.search;
      let arrVar = queryString.split(".");
      let urlRoute = queryString.replaceAll("?", "").replaceAll(".", "/");
      var val = urlRoute.split("/");
      this.parameterFromURL = val;
      this.$store
        .dispatch("school_user/_listSchool_User", { token: val[1] })
        .then(async (res) => {
          if (res.length >= 1) {
            await this.$store.dispatch("_setDefSchoolUser", res[0]);
            if (process.env.NODE_ENV == "development") {
              // this.profile = {
              //   profile: { userId: "U3493c6ec6d1dea4b5de4e8cb6e533d42" },
              //   token: { sch_user_token: "6a3a257c80d9f7a0d77e72d7bdcb21f9" },
              // }
              // this.checkUserTea(this.profile);
              this.$router.push('/login');
            }
            else
            {
              if (liff.getOS() != "web") {
                await this.checkUserFormLine();
                
              } else {
                this.$router.push('/login')
              }
            }

          }
        });

    },
    async checkUserFormLine() {
      this.dialogLoading = true;
      await liff.init({ liffId: "1660848685-qx0BjDJa" }).then(async () => {
        await liff
          .getProfile()
          .then(async (profile) => {
            this.dialogLoading = false;
            this.profile = profile;
            await this.checkUserTea(profile);
          }
        );
      });
    },
    async checkUserTea(value) {
      // let valSend = {
      //   profile: value,
      //   token: { sch_user_token: val[1] }
      // }
      //  this.profile1 = {
      //    profile: Object.assign({},value),
      //     token: { sch_user_token: val[1] }
      //   };
      this.$store
        .dispatch("teacher/_checkTeacher", {profile:value,token: { sch_user_token: this.parameterFromURL[1] } })
        .then(async (resCheck) => {
          // this.profile1 = resCheck;

          if (Array.isArray(resCheck)) {
            await this.$store.dispatch("_setDefTeacher", resCheck[0]);
            await this.$store.dispatch("menu_main_group/_listMenu_Main_Group_Tea", resCheck[0]);
            await this.$store.dispatch("authentication/_listMenu_Authentication", resCheck[0]);
            await Swal.fire({
              title: `${resCheck[0].sch_user_name}`,
              html: `ผู้ใช้งาน คือ ${resCheck[0].pre_name}${resCheck[0].tea_fname
                } ${resCheck[0].tea_lname
                }<br>เมื่อ ${moment().format(
                  "DD MMMM YYYY HH:mm:SS  น."
                )}`,
              imageUrl: `https://jm2.big24u.com/images/logos/${resCheck[0]?.sch_user_token}.jpg`,
              imageHeight: 200,
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: false,
            }).then(async (result) => {
              await this.$router.push(`/home/${resCheck[0]?.sch_user_token}`);
            });
          } else {
            Swal.fire({
              title: "Oop!!!!",
              text: "ทำการลงทะเบียนเพื่อใช้งานอีกครั้ง!!!",
              icon: "warning",
            })
              .then(() => {

                this.profile = `/regis/`+this.parameterFromURL[1]+`/`+value.userId;

                if (this.$route.path != `/regis/`+this.parameterFromURL[1]+`/`+value.userId) {
                  this.$router.push(`/regis/`+this.parameterFromURL[1]+`/`+value.userId);
                }
              });
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    checkToken(token) {
      // this.profile = token;
      this.$store
        .dispatch("school_user/_listSchool_User", { token: token[1] })
        .then((res) => {
          if (res.length == 1) {
            this.$store.dispatch("_setDefSchoolUser", res[0]);
            // this.profile = res;
            if (liff.getOS() != "web") {
              // ต้องเข้าผ่าน Line liff https://liff.line.me/1660848685-qx0BjDJa
              if (process.env.NODE_ENV != "development") {
                // liff.ready.then(() => {
                liff.init({ liffId: "1660848685-qx0BjDJa" }).then(async () => {
                  liff
                    .getProfile()
                    .then((profile1) => {
                      // ดึงค่าจาก Line
                      this.$store
                        .dispatch("teacher/_checkTeacher", {
                          profile: profile1,
                          token: this.setDefSchoolUser,
                        })
                        .then(async (resCheck) => {
                          // console.log(resCheck);
                          this.profile = resCheck;

                          if (Array.isArray(resCheck) && resCheck.length > 0) {
                            this.$store.dispatch("_setDefTeacher", resCheck[0]);
                            Swal.fire({
                              title: `${resCheck[0].sch_user_name}`,
                              html: `ผู้ใช้งาน คือ ${resCheck[0].pre_name}${resCheck[0].tea_fname
                                } ${resCheck[0].tea_lname
                                }<br>เมื่อ ${moment().format(
                                  "DD MMMM YYYY HH:mm:SS  น."
                                )}`,
                              imageUrl: `https://jm2.big24u.com/images/logos/${resCheck[0]?.sch_user_token}.jpg`,
                              imageHeight: 200,
                              timer: 1500,
                              timerProgressBar: true,
                              showConfirmButton: false,
                            }).then(async (result) => {
                              await this.$router.push(`/home/${token[1]}`);
                            });
                          } else {
                            Swal.fire({
                              title: "ผิดพลาด",
                              text: "ทำการลงทะเบียนเพื่อใช้งานอีกครั้ง!!!",
                              icon: "warning",
                            })
                              .then(() => {

                                this.profile = `/regis/${token[1]}/${profile.userId}`;

                                if (this.$route.path != `/regis/${token[1]}/${profile1.userId}`) {
                                  this.$router.push(`/regis/${token[1]}/${profile1.userId}`);
                                }
                              });
                          }
                        })
                        .catch((err) => {
                          console.log("error", err);
                        });
                    })
                    .catch((err) => {
                      console.log(err.code, err.message);
                    });
                });
                // });
              }
              else {
                this.$store
                  .dispatch("teacher/_checkTeacher", {
                    profile: { userId: "U3493c6ec6d1dea4b5de4e8cb6e533d42" },
                    token: { sch_user_token: "6a3a257c80d9f7a0d77e72d7bdcb21f9" },
                  })
                  .then(async (resCheck) => {
                    // console.log(resCheck);
                    // this.profile = token;
                    if (Array.isArray(resCheck) && resCheck.length > 0) {
                      this.$store.dispatch("_setDefTeacher", resCheck[0]);
                      Swal.fire({
                        title: `${resCheck[0].sch_user_name}`,
                        html: `ผู้ใช้งาน คือ ${resCheck[0].pre_name}${resCheck[0].tea_fname
                          } ${resCheck[0].tea_lname
                          }<br>เมื่อ ${moment().format(
                            "DD MMMM YYYY HH:mm:SS  น."
                          )}`,
                        imageUrl: `https://jm2.big24u.com/images/logos/${resCheck[0]?.sch_user_token}.jpg`,
                        imageHeight: 200,
                        timer: 1500,
                        timerProgressBar: true,
                        showConfirmButton: false,
                      }).then(async () => {
                        await this.$router.push(`/home/${token[1]}`);
                      });
                    } else {
                      Swal.fire({
                        title: "ผิดพลาด",
                        text: "ทำการลงทะเบียนเพื่อใช้งานอีกครั้ง!!!",
                        icon: "warning",
                      })
                        .then(() => {
                          this.profile = `/regis/${token[1]}/${profile.userId}`;
                          if (this.$route.path != `/regis/${token[1]}/${profile.userId}`) {
                            this.$router.push(`/regis/${token[1]}/${profile.userId}`);
                          }
                        });
                    }
                  })
                  .catch((err) => {
                    console.log("error", err);
                  });
              }
            }
            else {
              if (this.$route.path != "/login") {
                this.$router.push("/login");
              }
            }
          } else {
            if (this.$route.path != "/login") {
              this.$router.push("/login");
            }
          }
        });
    },
  },
  created() {
    this.getVariable();
  },
};
</script>
<style>
/* prompt-regular - thai */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Prompt";
  font-style: normal;
  font-weight: 400;
  src: url("/src/assets/fonts/prompt-v10-thai-regular.eot");
  /* IE9 Compat Modes */
  src: url("/src/assets/fonts/prompt-v10-thai-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("/src/assets/fonts/prompt-v10-thai-regular.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("/src/assets/fonts/prompt-v10-thai-regular.woff") format("woff"),
    /* Modern Browsers */
    url("/src/assets/fonts/prompt-v10-thai-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("/src/assets/fonts/prompt-v10-thai-regular.svg#Prompt") format("svg");
  /* Legacy iOS */
}

html,
*,
#app {
  font-family: "Prompt" !important;
}

.link-Normal {
  text-decoration: none;
}

.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  line-height: 1.4;
}

.v-text-field input {
  line-height: 28px;
}
</style>
