export default {
  namespaced: true,
  state: {
    listVote: null,
    listMembers: null,
    reportVoteStatic: null,
    listScores: null,
  },
  getters: {
    _listVote: (state) => state.listVote,
    _listMembers: (state) => state.listMembers,
    _listScores: (state) => state.listScores,
    _reportVoteStatic: (state) => state.reportVoteStatic,
  },
  mutations: {},
  actions: {
    _listScores({ state }, params) {
      return window.axios.post("vote/_listScores", params).then((res) => {
        state.listScores = res.data;
        return res.data;
      });
    },
    _listMembers({ state }) {
      return window.axios.post("vote/_listMembers").then((res) => {
        state.listMembers = res.data;
        return res.data;
      });
    },
    _listVote({ state }, params) {
      return window.axios.post("vote/_listVote", params).then((res) => {
        state.listVote = res.data;
        return res.data;
      });
    },
    _updateMember({ state }, params, headers) {
      return window.axios
        .post("vote/_updateMember", params, headers)
        .then((res) => res.data);
    },
    _deleteMembers({ state }, params) {
      return window.axios
        .post("vote/_deleteMembers", params)
        .then((res) => res.data);
    },
    _checkStudent2({ state }, params) {
      return window.axios
        .post("vote/_checkStudent2", params)
        .then((res) => res.data);
    },
    _saveVote({ state }, params) {
      return window.axios
        .post("vote/_saveVote", params)
        .then((res) => res.data);
    },
    _reportVoteStatic({ state }, params) {
      return window.axios
        .post("vote/_reportVoteStatic", params)
        .then((res) => { state.reportVoteStatic = res.data;  return res.data;});
    },
  },
};
