export default {
  namespaced: true,
  state: {
    listTeacher: null,
    listTeacherAll: null,
    checkTeacher: null,
  },
  getters: {
    _listTeacher: (state) => state.listTeacher,
    _listTeacherAll: (state) => state.listTeacherAll,
    _checkTeacher: (state) => state.checkTeacher,
  },
  mutations: {},
  actions: {
    _listTeacher({ state }, params) {
      window.axios
        .post("teacher/_listTeacher", params)
        .then((res) => (state.listTeacher = res.data));
    },
    _listTeacherAll({ state }, params) {
      return window.axios
        .post("teacher/_listTeacherAll", params)
        .then((res) => {
          state.listTeacherAll = res.data;
          return res.data;
        });
    },
    _regis_teacher({ state }, params) {
      return window.axios
        .post("teacher/_regis_teacher", params)
        .then((res) => res.data);
    },
    _checkTeacher({ state }, params) {
      return window.axios.post("teacher/_checkTeacher", params).then((res) => {
        state.checkTeacher = res.data;
        return res.data;
      });
    },
    _checkTeacherFormWeb({ state }, params) {
      return window.axios
        .post("teacher/_checkTeacherFormWeb", params)
        .then((res) => res.data);
    },
  },
};
