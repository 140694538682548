export default {
  namespaced: true,
  state: {
    list_IncomeExpanse_Account: null,
    list_ListType: null,
    list_InEx_Status: null,
  },
  getters: {
    _list_IncomeExpanse_Account: (state) => state.list_IncomeExpanse_Account,
    _list_ListType: (state) => state.list_ListType,
    _list_InEx_Status: (state) => state.list_InEx_Status,
    
  },
  mutations: {},
  actions: {
    
    _list_Cooperation_Unit({ state }) {
      return window.axios
        .post("finance/_list_Cooperation_Unit")
        .then((res) => (state.list_Cooperation_Unit = res.data));
    },
    _list_Cooperation_Payment({ state }, params) {
      return window.axios
        .post("finance/_list_Cooperation_Payment", params)
        .then((res) => (state.list_Cooperation_Payment = res.data));
    },
    // ------------------------- IncomeExpanse_Account
    _list_IncomeExpanse_Account({ state }) {
      return window.axios
        .post("finance/_list_IncomeExpanse_Account")
        .then((res) => (state.list_IncomeExpanse_Account = res.data));
    },
    _insert_IncomeExpanse_Account({ state },params) { 
      return window.axios
        .post("finance/_insert_IncomeExpanse_Account",params)
        .then((res) => (res.data));
    },
    _update_IncomeExpanse_Account({ state },params) { 
      return window.axios
        .post("finance/_update_IncomeExpanse_Account",params)
        .then((res) => (res.data));
    },
    _delete_IncomeExpanse_Account({ state },params) { 
      return window.axios
        .post("finance/_delete_IncomeExpanse_Account",params)
        .then((res) => (res.data));
    },
    // ------------------------- IncomeExpanse_Account
    // ------------------------- ListType
    _list_InEx_Status({ state }) {
      return window.axios
        .post("finance/_list_InEx_Status")
        .then((res) => (state.list_InEx_Status = res.data));
    },
    _list_ListType({ state }) {
      return window.axios
        .post("finance/_list_ListType")
        .then((res) => (state.list_ListType = res.data));
    },
    _insert_ListType({ state },params) { 
      return window.axios
        .post("finance/_insert_ListType",params)
        .then((res) => (res.data));
    },
    _update_ListType({ state },params) { 
      return window.axios
        .post("finance/_update_ListType",params)
        .then((res) => (res.data));
    },
    _delete_ListType({ state },params) { 
      return window.axios
        .post("finance/_delete_ListType",params)
        .then((res) => (res.data));
    },
    // ------------------------- ListType

  },
};
