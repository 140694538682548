import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/home/:token",
    name: "home",
    props: true,
    component: () => import("../views/homeView.vue"),
  },
  {
    path: "/regis/:token/:lineUserID",
    name: "regis",
    props: true,
    component: () => import("../views/regisView.vue"),
  },
  {
    path: "/main/:token",
    name: "main",
    props: true,
    component: () => import("../views/mainView.vue"),
  },
  {
    path: "/login",
    name: "login",
    props: true,
    component: () => import("../views/loginView.vue"),
  },
  //teamwork
  {
    path: "/teamwork",
    name: "teamwork",
    props: true,
    component: () => import("../views/teamworkView.vue"),
  },
  {
    path: "/teamwork/arrive/leave",
    name: "arrive-leave",
    props: true,
    component: () => import("../components/teamwork/arrive/listLeave.vue"),
  },
  {
    path: "/teamwork/arrive/reportStatic",
    name: "arrive-leave",
    props: true,
    component: () => import("../components/teamwork/arrive/reportStatic.vue"),
  },
  {
    path: "/teamwork/arrive/leave/approve/:token/:teaID",
    name: "arrive-leave-detail",
    props: true,
    component: () => import("../components/teamwork/arrive/detailLeave.vue"),
  },
  {
    path: "/menu/:menu_group_id",
    name: "menu",
    props: true,
    component: () => import("../views/mainView.vue"),
  },
  {
    path: "/manageNormal/managevote",
    name: "manageNormal-managevote",
    props: true,
    component: () => import("../components/manageNormal/vote/manageVote.vue"),
  },
  {
    path: "/manageNormal/vote",
    name: "manageNormal-vote",
    props: true,
    component: () => import("../components/manageNormal/vote/vote.vue"),
  },
  {
    path: "/manageNormal/reportVote",
    name: "manageNormal-reportVote",
    props: true,
    component: () => import("../components/manageNormal/vote/reportVote.vue"),
  },
  {
    path: "/manageNormal/regisMember",
    name: "manageNormal-regisMember",
    props: true,
    component: () => import("../components/manageNormal/vote/regisMember.vue"),
  },
  {
    path: "/finance/parcelRequisition",
    name: "finance-parcelRequisition",
    props: true,
    component: () => import("../components/finance/parcelRequisition.vue"),
  },
  {
    path: "/finance/parcelManage",
    name: "finance-parcelManage",
    props: true,
    component: () => import("../components/finance/parcelManage.vue"),
  },
  // --------------------------------------- cooperation
  {
    path: "/manageNormal/cooperation",
    name: "manageNormal-cooperation",
    props: true,
    component: () => import("../components/manageNormal/cooperation/index.vue"),
    children: [
      {
        path: '/',
        name: "listMenuCooperation",
        props: true,
        component: () => import("../components/manageNormal/cooperation/listMenu.vue"),
      },
      {
        path: '/manageNormal/cooperation/unit',
        name: "unit",
        props: true,
        component: () => import("../components/manageNormal/cooperation/unit.vue"),
      },
      {
        path: '/manageNormal/cooperation/account',
        name: "account",
        props: true,
        component: () => import("../components/manageNormal/cooperation/account.vue"),
      },
      {
        path: '/manageNormal/cooperation/order_account',
        name: "order_account",
        props: true,
        component: () => import("../components/manageNormal/cooperation/order_account.vue"),
      },
      {
        path: '/manageNormal/cooperation/relation_order_account',
        name: "relation_order_account",
        props: true,
        component: () => import("../components/manageNormal/cooperation/relation_order_account.vue"),
      },
      {
        path: '/manageNormal/cooperation/product',
        name: "product",
        props: true,
        component: () => import("../components/manageNormal/cooperation/product.vue"),
      },
      {
        path: '/manageNormal/cooperation/stock',
        name: "stock",
        props: true,
        component: () => import("../components/manageNormal/cooperation/stock.vue"),
      },
      {
        path: '/manageNormal/cooperation/payment',
        name: "payment",
        props: true,
        component: () => import("../components/manageNormal/cooperation/payment.vue"),
      },
      {
        path: '/manageNormal/cooperation/reportStock',
        name: "reportStock",
        props: true,
        component: () => import("../components/manageNormal/cooperation/reportStock.vue"),
      },
      {
        path: '/manageNormal/cooperation/reportPayment',
        name: "reportPayment",
        props: true,
        component: () => import("../components/manageNormal/cooperation/reportPayment.vue"),
      },
      {
        path: '/manageNormal/cooperation/reportPaymentProduct',
        name: "reportPaymentProduct",
        props: true,
        component: () => import("../components/manageNormal/cooperation/reportPaymentProduct.vue"),
      },
      {
        path: '/manageNormal/cooperation/accounting',
        name: "accounting",
        props: true,
        component: () => import("../components/manageNormal/cooperation/accounting.vue"),
      },
    ]
  },

  // --------------------------------------- cooperation
  // --------------------------------------- finance
  // /finance/IncomeExpanse
  {
    path: "/finance/incomeExpanse",
    name: "finance-incomeExpanse",
    props: true,
    component: () => import("../components/finance/incomeExpanse/index.vue"),
    children: [
      {
        path: '/',
        name: "listMenu",
        props: true,
        component: () => import("../components/finance/incomeExpanse/listMenu.vue"),
      },
      {
        path: '/finance/incomeExpanse/accountType',
        name: "accountType",
        props: true,
        component: () => import("../components/finance/incomeExpanse/accountType.vue"),
      },
      {
        path: '/finance/incomeExpanse/listAccount',
        name: "listAccount",
        props: true,
        component: () => import("../components/finance/incomeExpanse/listAccount.vue"),
      },
      {
        path: '/finance/incomeExpanse/listOrderType',
        name: "listMenu",
        props: true,
        component: () => import("../components/finance/incomeExpanse/listOrderType.vue"),
      },
      {
        path: '/finance/incomeExpanse/reportOrder',
        name: "reportOrder",
        props: true,
        component: () => import("../components/finance/incomeExpanse/reportOrder.vue"),
      },
      {
        path: '/finance/incomeExpanse/reportStatistic',
        name: "reportStatistic",
        props: true,
        component: () => import("../components/finance/incomeExpanse/reportStatistic.vue"),
      },
      {
        path: '/finance/incomeExpanse/reportDetail',
        name: "reportDetail",
        props: true,
        component: () => import("../components/finance/incomeExpanse/reportDetail.vue"),
      },
    ]
  }
  // --------------------------------------- finance

];

const router = new VueRouter({
  // mode: 'history',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
