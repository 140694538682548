export default {
  namespaced: true,
  state: { listMenu_Authentication: null },
  getters: {
    _listMenu_Authentication: (state) => state.listMenu_Authentication,
  },
  mutations: {},
  actions: {
    _listMenu_Authentication({ state }, params) {
      return window.axios
        .post("authentication/_listMenu_Authentication", params)
        .then((res) => (state.listMenu_Authentication = res.data));
    },
  },
};
